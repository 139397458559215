<template>
  <div>
      <v-layout wrap>
       <v-flex xs12>
        <v-btn text>
         <v-icon color="white" ripple ="false" @click="dialoge = true">mdi-delete-outline</v-icon>
        </v-btn>
       </v-flex>
      </v-layout>
      <v-dialog width="400px" v-model="dialoge">

    <v-card width="400px">
     <!-- <v-layout wrap pa-3>
         <v-flex xs12>
        Are you sure..? you want to delete this person...?
         </v-flex>
         <v-flex xs12 text-right>
              <v-btn text @click="dialoge =false" >
               No
              </v-btn>
              <v-btn text >
yes
              </v-btn>
         </v-flex>
     </v-layout> -->
     <v-toolbar dark color="#766BC0"  dense flat class="body-2">
         <v-toolbar-title >
             Delete Student
         </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-4 black--text">Are you sure you want to delete this student?
    </v-card-text>
    <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
        
          color="grey lighten-3"
          text
          class="body-2 font-weight-bold"
          @click="dialoge =false"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
         @click="deleteItem()"
          >OK</v-btn
        >
    </v-card-actions>
     
    </v-card>
      </v-dialog>
     <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>                 
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        dialoge:false,
      dialogDelete: false,
      data: [],
      msg: null,
      showsnackbar: false,
    };
  },
  components: {
    // ImageComp,
  },
 
  methods: {
   
    // getdData() {
    
    //   this.getData();
    // },

    deleteItem() {
      var data = {};
      data.id = this.$route.query.id;
      axios({ 
        url: "/remove/student",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.body-2{
    background-color: grey lighten-3 ;
}
</style>

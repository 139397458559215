<template>
  <v-layout wrap justify-center>
    <v-flex xs12 sm8 md8 lg8 py-6>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <!------------------------------------------------------------------------------------------------------------------------------->
      <v-card outlined>
        <v-layout
          wrap
          justify-center
          v-if="user.admissionNo"
          style="
           
          "
          class="bgg pa-md-8"
        >
          <v-flex xs12 text-right align-self-end>
            <deleteStudent style="cursor: pointer" />
          </v-flex>
          <v-flex xs12 lg10 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 text-center>
                <v-avatar size="96px" radius="10px">
                  <img
                    src="@/assets/images/Ellipse23.png"
                    alt="John"
                    class="pa-1"
                  />
                </v-avatar>
              </v-flex>
              <v-flex xs12 pb-2 text-center>
                <span class="nssbold" style="font-size: 18px; color: #ffffff">
                  {{ user.name }}
                </span>
              </v-flex>
              <v-flex xs12 text-center v-if="user.studentclass">
                <v-chip
                  elevation="2"
                  rounded
                  color="#766BC0"
                  class="white--text"
                  style="font-weight: 500; font-size: 14px"
                  v-if="user.studentclass"
                  >{{ user.studentclass.className }}</v-chip
                >
                <v-chip
                  dark
                  elevation="2"
                  class="ml-2"
                  rounded
                  outlined
                  v-if="user.admissionNo"
                  style="font-weight: 400; font-size: 14px"
                  >Adm. No.: {{ user.admissionNo }}</v-chip
                >
                <v-chip
                  dark
                  elevation="2"
                  class="ml-2"
                  rounded
                  outlined
                  style="font-weight: 400; font-size: 14px"
                  v-if="user.school"
                  >School: {{ user.school.SchoolName }}</v-chip
                >
                 <v-chip
                  dark
                  elevation="2"
                  class="ml-2"
                  rounded
                  outlined
                  style="font-weight: 400; font-size: 14px"
                  v-if="user.studentHostel"
                  >Hostel: {{ user.studentHostel.hostelName }}</v-chip
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card class="mx-auto pa-6" max-width="1087px" align="center" outlined>
        <!-------------------------------------------edit------------------------------------------>

        <v-layout wrap>
          <v-flex xs12 text-right>
            <v-icon>mdi-edit</v-icon>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon>mdi-circle-edit-outline</v-icon> Edit</v-btn
                >
              </template>
              <v-card v-if="editUser">
                <v-card-title>
                  <span v-if="editUser.admissionNo" class="text-h5"
                    >Admission.No: {{ editUser.admissionNo }}</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-layout wrap class="nsregular">
                    <v-flex xs12 sm6 md6>
                      <!-- {{editUser}} -->
                      <v-text-field
                        v-model="editUser.name"
                        label="First name"
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 sm6 md6 pl-4>
                      <v-text-field
                        v-model="user.lname"
                        label="Last name"
                      ></v-text-field>
                    </v-flex> -->
                    <v-flex xs12 sm6 md6 pl-4>
                      <v-select
                        :items="gender"
                        v-model="editUser.gender"
                        label="Gender"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                     <v-select
                hide-details
                :items="classList"
                v-model="editUser.classid"
                label="Class"
                item-text="standardName"
                item-value="_id"
                dense
                @input="getDivision()"
              ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6 pl-4>
                     <v-select
                hide-detail
                :items="divisionList"
                v-model="editUser.divisionId"
                label="Division"
                item-text="divisionName"
                item-value="_id"
                hide-details
                dense
              ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6 pt-5>
                      <!-- <v-text-field
                        v-model="user.DOB"
                        label="DOB"
                      ></v-text-field> -->
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="editUser.DOB"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editUser.DOB"
                            label="Date of birth"
                            hide-details
                            readonly
                            dense
                            class="rounded-0"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editUser.DOB"
                          no-title
                          scrollable
                          @change="$refs.menu.save(editUser.DOB)"
                        >
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <!-- <v-flex xs12>
                      <v-text-field
                        v-model="user.email"
                        :rules="[rules.email]"
                        label="Email"
                      ></v-text-field>
                    </v-flex> -->
                    <v-flex xs12 sm6 md6 pl-4>
                      <v-text-field
                        v-model="editUser.religion"
                        label="Religion"
                      ></v-text-field>
                    </v-flex>
                     <v-flex xs12 sm6 md6>
                     <v-text-field
                dense
                v-model="editUser.caste"
                label="Caste"
                class="pr-4"
              ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 pl-4>
                    <v-select
                :items="SchoolList"
                v-model="editUser.school"
                label="school"
                item-text="SchoolName"
                item-value="_id"
                dense
              ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editUser.nameofFather"
                        label="Father's name"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 pl-4>
                      <v-text-field
                        v-model="editUser.nameOfMother"
                        label="Mother's name"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editUser.parentContactNumber"
                        label="Contact"
                      ></v-text-field>
                    </v-flex>
                     <v-flex xs12 sm6 md6 pl-4>
                      
                      <v-select
                :items="HostelList"
                v-model="editUser.studentHostel"
                label="Hostel"
                item-text="hostelName"
                item-value="_id"
                dense
              ></v-select>
                    </v-flex>
                    <!-- <v-flex xs12 sm6 md6>
                       <v-select
                :items="HostelList"
                v-model="editUser.studentHostel"
                label="Hostel"
                item-text="hostelName"
                item-value="_id"
                hide-details
                dense
                :rules="[rules.required]"
              ></v-select>
                    </v-flex> -->
                   
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false, getData()">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="edit()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!------------------------------------------------------------>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6 class="text-left pt-4">
            <span class="nsbold" style="font-weight: 600; font-size: 24px"
              >Student Information</span
            >
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-account</v-icon></v-flex>
                <v-flex xs8>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Name
                  </span>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.name
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-gender-male</v-icon></v-flex>
                <v-flex xs8>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Gender
                  </span>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.gender
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-calendar-range</v-icon></v-flex>
                <v-flex xs8>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    DOB
                  </span>
                  <v-flex class="nsregular" style="font-size: 17px">{{
                    formatDate(user.DOB)
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-menu-open</v-icon></v-flex>
                <v-flex xs3>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Religion
                  </span>
                  <v-flex class="nsregular" style="font-size: 17px">{{
                    user.religion
                  }}</v-flex>
                </v-flex>
                <v-flex xs4>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Caste
                  </span>
                  <v-flex class="nsregular" style="font-size: 17px">{{
                    user.caste
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <!-- <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-email-outline</v-icon></v-flex>
                <v-flex xs8>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    E-mail
                  </span>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.email
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card> -->
          </v-flex>
          <v-flex xs6 class="text-left ml-6 pt-4">
            <span class="nsbold" style="font-weight: 600; font-size: 24px"
              >Parent Information</span
            >
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-account</v-icon></v-flex>
                <v-flex xs8>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Father's Name
                  </span>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.nameofFather
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-account</v-icon></v-flex>
                <v-flex xs8>
                  <span
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Mother's Name
                  </span>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.nameOfMother
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-cellphone</v-icon></v-flex>
                <v-flex xs8>
                  <p
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Phone
                  </p>
                  <v-flex class="nsregular" style="font-size: 17px">{{
                    user.parentContactNumber
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <!-- <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-email-outline</v-icon></v-flex>
                <v-flex xs8>
                  <p
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    E-mail
                  </p>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.fathersemail
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
           
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-cellphone</v-icon></v-flex>
                <v-flex xs8>
                  <p
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    Phone
                  </p>
                  <v-flex class="nsregular" style="font-size: 17px">{{
                    user.mothersphone
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card :elevation="0" class="pa-4">
              <v-layout wrap>
                <v-flex xs1><v-icon>mdi-email-outline</v-icon></v-flex>
                <v-flex xs8>
                  <p
                    class="my-0"
                    style="color: gray; font-weight: 200; font-size: 17px"
                  >
                    E-mail
                  </p>
                  <v-flex class="nsregular" style="font-size: 18px">{{
                    user.mothersemail
                  }}</v-flex>
                </v-flex>
              </v-layout>
            </v-card> -->
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import deleteStudent from "../deleteStudent";
import axios from "axios";
export default {
  components: {
    deleteStudent,
  },
  data() {
    return {
      menu: null,
      appLoading: false,
      gender: ["Male", "Female", "Other"],

      // classid:null,
     user: {
      // standardid:null,
        name: null,
        classid: null,
        divisionId: null,
        gender: null,
        NameofFather: null,
        NameofMother: null,
        admissionNo: null,
        parentContactNumber: null,
        DOB: null,
        religion: null,
        caste: null,
        school: null,
        studentHostel: null,
      },
      editUser:null,
      classList: [],
      divisionList: [],
      HostelList: [],
      SchoolList: [],
      hostel: null,
      school: null,
      hostelName: null,
      myClass: null,
      dialog: false,
      rules: {
        required: (value) => !!value || "Required.",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.getData();
    this.getclass();
    this.getschool();
    this.gethostel();
    this.getDivision();
    

  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/student/get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.user = response.data.data;
            // this.user.school=this.user.school.schoolName
            this.user.DOB = this.user.DOB.slice(0,10);
            
            this.editUser = this.user

            this.editUser.classid = this.user.studentclass.standard

            this.getDivision()
            this.editUser.divisionId=this.user.studentclass.division
            // this.editUser.hostel=this.user.hostel
            this.appLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
     getschool() {
      axios({
        method: "get",
        url: "/school/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.SchoolList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getclass() {
      axios({
        method: "get",
        url: "/standard/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.classList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    gethostel() {
      axios({
        method: "get",
        url: "/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getDivision() {
      axios({
        method: "get",
        url: "/division/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.editUser.classid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.divisionList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    show() {
      this.content = this.user;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    edit() {
      this.user.id = this.$route.query.id;
      this.user.standardid = this.editUser.classid;
      this.user.division = this.editUser.divisionId;

      axios({
        method: "post",
        url: "/studentdetails/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.user,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editUser = response.data.data;
          }
          this.dialog = false;
          this.getData();
        })
        .catch((err) => {
          console.log(err());
        });
    },
  },
};
</script>
<style scoped>
.bgg{
 background: transparent
              linear-gradient(104deg, #434343 0%, #484848 52%, #2c2b2b 100%) 0%
              0% no-repeat padding-box;
            border-radius: 6px 6px 0px 0px;
            opacity: 1;
}
</style>
